.base {
  display: inline-block;
  background: #123;
  width: 7rem;
  vertical-align: top;
}

.ta-c {
  text-align: center;
}

.p-m {
  padding: 1rem;
}

.p-s {
  padding: 0.5rem;
}

.m-xs {
  margin: 0.3rem;
}

.pb-0 {
  padding-bottom: 0;
}

.text {
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
}

.combo {
  list-style: none;
  margin: 0;
  padding: 0;
}

.combo li {
  white-space: nowrap;
  background: #234;
}

.combo li:before, .prefixed:before {
  content: '+';
  display: inline;
  font-size: 2rem;
  line-height: 1rem;
  vertical-align: top;
}

.prefixed.arr:before {
  content: '»';
  padding-left: 0.7rem;
}

.gives {
  padding-top: 0.3rem;
}

.smallDesc {
  font-size: 0.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}